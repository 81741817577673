import { apiGetData, apiPostData } from '@/services/ApiService.js'

const state = () => ({
  count: 0,
  waitForResponse: false,
  hash: '8b79e32e0748ee1bca9069c795df527e4ade5d2d',
  messages: [],
  refresh: false,
})

const actions = {
  // General

  loadMessages({ commit, state }) {
    state.waitForResponse = true
    apiGetData('messages/notifications/get-unread-top-list')
      .then((response) => {
        commit('LOAD_MESSAGES', response)
      })
      .finally(() => {
        state.waitForResponse = false
      })
  },

  hashUpdate() {
    apiGetData('messages/notifications/get-hash')
  },

  hashCompare({ state, dispatch }, hash) {
    if (hash != state.hash) {
      if (!state.waitForResponse) {
        dispatch('loadMessages', false)
      }
    }
  },

  setRefresh({ commit }, r) {
    commit('SET_REFRESH', r)
  },

  setMessageRead({ dispatch }, messageId) {
    return apiGetData(`messages/notifications/read/${messageId}`)
      .then((response) => {
        if (response.success) {
          //dispatch('loadMessages', false)
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err // reject
      })
  },
}

const mutations = {
  LOAD_MESSAGES(state, data) {
    state.messages = data.list ?? []
    state.count = data.count ?? 0
    state.hash = data.sha1 ?? '8b79e32e0748ee1bca9069c795df527e4ade5d2d'
  },

  SET_REFRESH(state, r) {
    state.refresh = r
  },
}

const getters = {
  getMessages: (state) => {
    return state.messages
  },
  getCount: (state) => {
    return state.count
  },
  getHash: (state) => {
    return state.hash
  },
  getRefresh: (state) => {
    return state.refresh
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import { apiGetData } from '@/services/ApiService.js'

const state = () => ({
  templatekinds: [{ id: 0, name: '-' }],
  taskKinds: [{ id: 0, name: '-' }],
  taskSubKinds: [{ id: 0, name: '-' }],
})

const actions = {
  loadTemplateKinds({ commit }) {
    apiGetData('bpo/template-kinds/populate').then((response) => {
      commit('LOAD_TEMPLATE_KINDS', response)
    })
  },

  loadTaskKinds({ commit }) {
    apiGetData('bpo/task-kinds/populate').then((response) => {
      commit('LOAD_TASK_KINDS', response)
    })
  },

  loadTaskSubKinds({ commit }, taskId) {
    if (!taskId) {
      commit('LOAD_TASK_SUB_KINDS', [])
      return false
    }
    apiGetData(`bpo/task-sub-kinds/populate/${taskId}`).then((response) => {
      commit('LOAD_TASK_SUB_KINDS', response)
    })
  },
}

const mutations = {
  LOAD_TEMPLATE_KINDS(state, templatekinds) {
    state.templatekinds = templatekinds
  },
  LOAD_TASK_KINDS(state, taskKinds) {
    state.taskKinds = taskKinds
  },
  LOAD_TASK_SUB_KINDS(state, taskSubKinds) {
    state.taskSubKinds = taskSubKinds
  },
}

const getters = {
  getTemplateKinds: (state) => {
    return state.templatekinds
  },

  getTaskKinds: (state) => {
    return state.taskKinds
  },

  getTaskSubKinds: (state) => {
    return state.taskSubKinds
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "GeneralView" */ '@/views/common/layout/LoggedView.vue'
      ),
    children: [
      {
        path: '',
        name: 'TaskAreaRedirect',
        redirect: { name: 'TaskAreaBoard' },
      },

      //task
      {
        path: 'task',
        name: 'TaskArea',
        component: () =>
          import(
            /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
          ),

        children: [
          {
            path: '',
            name: 'TaskAreaBoardRedirect',
            redirect: { name: 'TaskAreaBoard' },
          },

          //board
          {
            path: 'board',
            name: 'TaskAreaBoard',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/BoardView.vue'
              ),
          },

          {
            path: 'sent',
            name: 'TaskAreaSent',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/SentTasksView.vue'
              ),
          },

          {
            path: 'received',
            name: 'TaskAreaReceived',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/ReceivedTasksView.vue'
              ),
          },

          {
            path: 'archived',
            name: 'TaskAreaArchived',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/ArchivedTasksView.vue'
              ),
          },

          {
            path: 'view/:taskId',
            props: (route) => ({
              taskId: parseInt(route.params.taskId),
            }),
            name: 'TaskAreaView',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/TaskView.vue'
              ),
          },

          {
            path: 'edit/:taskId/:templateId?/:parentId?',
            props: (route) => ({
              taskId: parseInt(route.params.taskId),
              templateId: parseInt(route.params.templateId) || 0,
              parentId: parseInt(route.params.parentId) || 0,
            }),
            name: 'TaskAreaEdit',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/EditTasksView.vue'
              ),
          },

          {
            path: 'templates/:parentId?',
            props: (route) => ({
              parentId: parseInt(route.params.parentId) || 0,
            }),
            name: 'TaskAreaSelectTemplate',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/SelectTemplateView.vue'
              ),
          },
        ],
      },

      //groups
      {
        path: 'groups',
        name: 'Groups',
        component: () =>
          import(
            /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
          ),

        children: [
          {
            path: '',
            name: 'GroupsListRedirect',
            redirect: { name: 'GroupsList' },
          },

          //list
          {
            path: ':kind/list',
            props: (route) => ({
              kind: route.params.kind,
            }),
            name: 'GroupsList',
            component: () =>
              import(/* webpackChunkName: "Groups" */ '@/views/GroupsView.vue'),
          },

          //edit
          {
            path: ':kind/edit/:groupId',
            props: (route) => ({
              kind: route.params.kind,
              groupId: parseInt(route.params.groupId) || 0,
            }),
            name: 'GroupsEdit',
            component: () =>
              import(/* webpackChunkName: "Groups" */ '@/views/GroupView.vue'),
          },
        ],
      },

      //config
      {
        path: 'config',
        name: 'ConfigArea',
        component: () =>
          import(
            /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
          ),

        children: [
          {
            path: '',
            name: 'ConfigEventsRedirect',
            redirect: { name: 'ConfigEvents' },
          },

          //events
          {
            path: 'events',
            name: 'ConfigEvents',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigEventsListRedirect',
                redirect: { name: 'ConfigEventsList' },
              },

              //list
              {
                path: 'list',
                name: 'ConfigEventsList',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigEvents" */ '@/views/EventsView.vue'
                  ),
              },

              //edit
              {
                path: 'edit/:eventId',
                props: (route) => ({
                  eventId: parseInt(route.params.eventId) || 0,
                }),
                name: 'ConfigEventsEdit',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigEvents" */ '@/views/EventView.vue'
                  ),
              },

              //kinds
              {
                path: 'kinds',
                name: 'ConfigEventsKinds',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigEventsKindsListRedirect',
                    redirect: { name: 'ConfigEventsKindsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigEventsKindsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigEvents" */ '@/views/EventsKindsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:kindId',
                    props: (route) => ({
                      kindId: parseInt(route.params.kindId) || 0,
                    }),
                    name: 'ConfigEventsKindsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigEvents" */ '@/views/EventsKindView.vue'
                      ),
                  },
                ],
              },

              //profiles
              {
                path: 'profiles',
                name: 'ConfigEventsProfiles',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigEventsProfilesListRedirect',
                    redirect: { name: 'ConfigEventsProfilesList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigEventsProfilesList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigEvents" */ '@/views/EventsProfilesView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:profileId',
                    props: (route) => ({
                      profileId: parseInt(route.params.profileId) || 0,
                    }),
                    name: 'ConfigEventsProfilesEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigEvents" */ '@/views/EventsProfileView.vue'
                      ),
                  },
                ],
              },
            ],
          },

          //tasks
          {
            path: 'tasks',
            name: 'ConfigTasks',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigTasksKindsListRedirect',
                redirect: { name: 'ConfigTasksKindsList' },
              },

              //kinds
              {
                path: 'kinds',
                name: 'ConfigTasksKinds',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigTasksKindsListRedirect',
                    redirect: { name: 'ConfigTasksKindsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigTasksKindsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTasks" */ '@/views/TasksKindsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:kindId',
                    props: (route) => ({
                      kindId: parseInt(route.params.kindId) || 0,
                    }),
                    name: 'ConfigTasksKindsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTasks" */ '@/views/TasksKindView.vue'
                      ),
                  },
                ],
              },

              //sub-kinds
              {
                path: 'sub-kinds',
                name: 'ConfigTasksSubKinds',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigTasksSubKindsListRedirect',
                    redirect: { name: 'ConfigTasksSubKindsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigTasksSubKindsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTasks" */ '@/views/TasksSubKindsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:kindId',
                    props: (route) => ({
                      kindId: parseInt(route.params.kindId) || 0,
                    }),
                    name: 'ConfigTasksSubKindsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTasks" */ '@/views/TasksSubKindView.vue'
                      ),
                  },
                ],
              },
            ],
          },

          //templates
          {
            path: 'templates',
            name: 'ConfigTemplates',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigTemplatesKindsListRedirect',
                redirect: { name: 'ConfigTemplatesKindsList' },
              },

              //list
              {
                path: 'list',
                name: 'ConfigTemplatesList',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigTemplates" */ '@/views/TemplatesView.vue'
                  ),
              },

              //edit
              {
                path: 'edit/:templateId',
                props: (route) => ({
                  templateId: parseInt(route.params.templateId) || 0,
                }),
                name: 'ConfigTemplatesEdit',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigTemplates" */ '@/views/TemplateView.vue'
                  ),
              },

              //kinds
              {
                path: 'kinds',
                name: 'ConfigTemplatesKinds',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigTemplatesKindsListRedirect',
                    redirect: { name: 'ConfigTemplatesKindsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigTemplatesKindsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTemplates" */ '@/views/TemplateKindsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:kindId',
                    props: (route) => ({
                      kindId: parseInt(route.params.kindId) || 0,
                    }),
                    name: 'ConfigTemplatesKindsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigTemplates" */ '@/views/TemplateKindView.vue'
                      ),
                  },
                ],
              },
            ],
          },

          //actions
          {
            path: 'actions',
            name: 'ConfigActions',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigActionsKindsListRedirect',
                redirect: { name: 'ConfigActionsKindsList' },
              },

              //list
              {
                path: 'list',
                name: 'ConfigActionsList',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigActions" */ '@/views/ActionsView.vue'
                  ),
              },

              //edit
              {
                path: 'edit/:actionId',
                props: (route) => ({
                  actionId: parseInt(route.params.actionId) || 0,
                }),
                name: 'ConfigActionsEdit',
                component: () =>
                  import(
                    /* webpackChunkName: "ConfigActions" */ '@/views/ActionView.vue'
                  ),
              },

              //kinds
              {
                path: 'kinds',
                name: 'ConfigActionsKinds',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigActionsKindsListRedirect',
                    redirect: { name: 'ConfigActionsKindsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigActionsKindsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigActions" */ '@/views/ActionKindsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:kindId',
                    props: (route) => ({
                      kindId: parseInt(route.params.kindId) || 0,
                    }),
                    name: 'ConfigActionsKindsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigActions" */ '@/views/ActionKindView.vue'
                      ),
                  },
                ],
              },
            ],
          },

          //board
          {
            path: 'board',
            name: 'ConfigBoard',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigBoardColumnsListRedirect',
                redirect: { name: 'ConfigBoardColumnsList' },
              },

              //columns
              {
                path: 'columns',
                name: 'ConfigBoardColumns',
                component: () =>
                  import(
                    /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
                  ),

                children: [
                  {
                    path: '',
                    name: 'ConfigBoardColumnsListRedirect',
                    redirect: { name: 'ConfigBoardColumnsList' },
                  },

                  //list
                  {
                    path: 'list',
                    name: 'ConfigBoardColumnsList',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigBoardColumns" */ '@/views/BoardColumnsView.vue'
                      ),
                  },

                  //edit
                  {
                    path: 'edit/:columnId',
                    props: (route) => ({
                      columnId: parseInt(route.params.columnId) || 0,
                    }),
                    name: 'ConfigBoardColumnsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigBoardColumns" */ '@/views/BoardColumnView.vue'
                      ),
                  },

                  //transition
                  {
                    path: 'transition/:columnId/:transitionId',
                    props: (route) => ({
                      columnId: parseInt(route.params.columnId) || 0,
                      transitionId: parseInt(route.params.transitionId) || 0,
                    }),
                    name: 'ConfigBoardTransitionsEdit',
                    component: () =>
                      import(
                        /* webpackChunkName: "ConfigBoardColumns" */ '@/views/TransitionView.vue'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/common/layout/PublicView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
  //   if (to.matched.some((record) => record.meta.authorizationRequired)) {
  //     if (store.getters['session/getAuthStatus']) {
  //       next()
  //       return
  //     }
  //     console.log('/users/login')
  //     //next('/users/login')
  //   } else {
  //     next()
  //   }
})

router.afterEach(() => {
  NProgress.done()
})

export default router

import { createStore } from 'vuex'

import * as getters from '@/store/getters'
import * as actions from '@/store/actions.js'
import * as mutations from '@/store/mutations'

import genericColection from '@/store/modules/genericColection.js'
import toaster from '@/store/modules/toaster.js'
import session from '@/store/modules/session.js'
import paginator from '@/store/modules/paginator.js'
import bookmarks from '@/store/modules/bookmarks.js'
import screen from '@/store/modules/screen.js'
import roots from '@/store/modules/roots.js'
import notifications from '@/store/modules/notifications.js'
import task from '@/store/modules/task.js'

const state = {
  updatePending: 0,
}

export default createStore({
  modules: {
    genericColection,
    toaster,
    session,
    paginator,
    bookmarks,
    screen,
    roots,
    notifications,
    task,
  },
  state,
  getters,
  mutations,
  actions,
})
